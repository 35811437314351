<template>
  <div class="rightRecommend">
    <div class="header">
      <div>为你推荐</div>
    </div>

    <div class="list">
      <div class="list_item" v-for="(item, index) in list" :key="index">
        <div class="title">
          <img :src="item.img" alt="" />

          <span> {{ item.name }}</span>
        </div>

        <div class="rank">
          <div
            @click="rankClick(v.md5Label)"
            :title="v.title"
            class="rank_item"
            v-for="(v, i) in item.rank"
            :key="i"
          >
            {{ v.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import { LibraryIndexList } from "api/common";
export default {
  name: "rightRecommend",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      list: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getList() {
      const res = await LibraryIndexList();
      if (res.code == 200) {
        this.list = res.data.map((item, index) => {
          item.img = require("assets/images/swiper" + (index + 1) + ".png");

          return item;
        });
      }
    },

    rankClick(md5) {
      this.$emit("rankClick", md5);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.rightRecommend {
  .header {
    margin-top: 35px;
    padding-left: 5px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    border-left: solid 5px #ff6900;
  }
  .list {
    .list_item {
      padding: 0 10px;
      .title {
        margin: 20px 0;
        color: #4587ff;
        font-weight: bold;
        font-size: 14px;
        position: relative;

        span {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          position: absolute;
          top: 20px;
          left: 22px;
          z-index: 9;
        }
        img {
          width: 100%;
          // height: 4;
        }
      }
      .rank {
        .rank_item {
          cursor: pointer;
          margin: 20px 0;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;

          &:hover {
            color: #ff6900;
          }
        }
      }
    }
  }
}
</style>
